:root {
  --main-bg-color: #333;
  --main-txt-color: rgb(177, 172, 145);
  --highlighted-txt-color: white;
  --icon-color: orange;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-highlighted-color);
}

.App-link {
  color: #61dafb;
}

a {
  color: white;
}
a:hover {
  color: var(--icon-color);
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.imageTile {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  transition: 0.4s; 
}
.imageTile .imageTile-image {
  width: inherit;
  height: inherit;
  border-radius: 4px;
}
.imageTile .imageTile-image img{
  width: inherit;
  height: inherit;
  border-radius: 4px;
  object-fit: cover;
}
.imageTile:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25);
}

.imageTile-text:hover {
  transition: 0.4s; 
  transform: translateY(-100px);
}




/**border*/

.draw-border {
  transition: box-shadow .3s;
  border-radius: 5px;
}
.draw-border:hover {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  color: var(--highlighted-txt-color);
}
/*border end*/

/*custom css*/

html, body {
  height: 100%;
}

.full-height {
  min-height: 1000px;
  height: 100% !important;
  padding: 0px 0px 20px 0px !important;
  width: 100%;
  background: var(--main-bg-color);
  /* background: linear-gradient(to bottom, black,black, rgb(2, 2, 80)); */
  color: var(--highlighted-txt-color);

  /* background-image: url('/images/sq.png');
  background-repeat: no-repeat; */
}
.footer {
  text-align: center;
  position: fixed;
  font-size: xx-small;
  height: 20px;
  bottom: 0;
  width: 100%;
  color: var(--main-txt-color);
  background-color: black;
  border-top: 1px solid grey;
}

.nopadding {
  padding: 0;
}

.registration {
  background-color: white;
  position: relative;
  width: 80%;
  height: 2100px;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.title {
  align-items: center;
  color: #E4E5E6;
  display: flex;
  /* font-family: 'Impact', sans-serif; */
  text-shadow: 2px 2px black;
}
.navbar.bg-dark {
  background-color:  var(--main-bg-color)!important;
  background-image: url('/images/header.png');
  background-size: 200px;
  background-repeat: no-repeat;
  background-origin: content-box, padding-box;
  background-position: 35px 0px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  border-width: 0;
}
.normaltext {
  padding: 10 10 10 10 px!important;
  margin: 10px!important;
  text-align: center;
}

.pageTitle{
  font-size: large;
}
.tile {
  max-width: 343px;
  background-color:  inherit;
  color: var(--main-txt-color);
  text-align: center;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.8);
}
.tileWide {
  background-color:  inherit;
  color: var(--main-txt-color);
  text-align: center;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
}
.tileHeader {
  color: var(--highlighted-txt-color);
  font-size: large;
  padding-bottom: 5px;;
}

.tileBody {
  padding: 25px 15px;
  min-height: 100px;
}

.tileContainer{
  justify-content: center; 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tileContainerBGText {
  color: black;
}
.subHeader {
  color: white;
  float: right;
  text-align: center;
  padding:  2px;
  margin-right: 10px;
  min-width: 100px;
  border-radius: 5px;
  background-color:  var(--main-bg-color);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
}

.logo{
  margin: -3px -5px;
}
.highlightedText{
  color: var(--highlighted-txt-color);
}

.itemList {
  max-width: 433px;
  background-color:  inherit;
  padding: 15px 0px 0px 0px;
  margin: 0px;
}
.itemListContainer{
  font-size: x-small;
  padding: 10px 0px;
}
.itemListHeader {
  color:var(--icon-color);
  font-size: large;
  padding-bottom: 5px;
  margin: 0px;
}
.itemListNamePosition{
  color: white;
  text-align: right;
  padding: 0px;
  margin: 0px;
}
.itemListName{
  text-align: left;
  padding: 0px 0px 0px 5px;
  margin: 0px;
}

.fas{
  color: var(--icon-color);
}
.fab {
  color: var(--icon-color);
}
.contactList{
  text-align: left;
}

.horzontallyScrollableContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.eventImageContainer{
  max-width: 290px;
  padding: 0px;
}
.eventImage{
  cursor: pointer;
  width: 290px;
  padding: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
}
.fullScreenImage{
  width: 100%;
}

.albumTitle{
  color: var(--highlighted-txt-color);
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
}

.sponsor{
  text-align: center;
}
.sponsorLogo{
  max-height: 60px;
}
